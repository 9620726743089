<template>
  <div>

    <el-container class="outside-container">

        <el-aside :width="asideWidth">
      <side-bar/>
    </el-aside>


      <el-container class="inside-container">

   <el-header>

        <div class="logoJump">
          <div class="toggle-button" @click="toggleCollapse">
<!--            <span >点我试试</span>-->
            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-s-fold"></i>

<!--        <span v-if="isCollapse">>>点我</span>-->
<!--        <span v-else>&lt;&lt;点我</span>-->
      </div>

          <div class="uname">
            <!--            <div class="avatarimgtar-username">-->
            <img src='../assets/header.png' class="avatarimg"/>
            <el-dropdown>
            <h2>{{ userName }}<i class="el-icon-caret-bottom"></i></h2>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="modifyPassord">修改密码</el-dropdown-item>
                            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>


            <!--            </div>-->
<!--            <el-button @click="logout" type="text">登出</el-button>-->
          </div>

        </div>
      </el-header>
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
    </el-container>

    <el-drawer
  :visible.sync="drawer"
  :direction="direction"
    :size="140">
      <side-bar/>
</el-drawer>

  </div>
</template>

<script>
import sideBar from "@/components/sideBar";

export default {
  name: "Layout",
  components: {
    sideBar,
  },
  data() {
    return {
      userName: '',
      avatarUrl: '../assets/header.png',
      containerHeight: '100vh',
      isCollapse: false,

      device: 'desktop',
      asideWidth: '140px',
      // sidebarIsOpen: true,
      bodyWiDthLevel : 992,

      drawer: false,
      direction: 'ltr'

    };
  },
  watch: {
    $route() {
      if (this.device === 'mobile') {
        this.drawer = false
        this.asideWidth = '0px'

      }
    }
  },
 // 注册一个监听页面大小的事件
 beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)

  },
    beforeDestroy() {

    window.removeEventListener('resize', this.$_resizeHandler)
  },

  mounted() {


    const isMobile = this.$_isMobile()
    if (isMobile ) {
      this.closeSideBar()
      this.device = 'mobile'
    }

    // 在页面加载时获取 localStorage 中的 userName
    this.userName = localStorage.getItem('userName');

//     const redirectRoute = localStorage.getItem('redirect');
// if (redirectRoute) {
//   // 清除已存储的跳转信息
//   localStorage.removeItem('redirect');
//   // 跳转到存储的路由
//   this.$router.push(redirectRoute);
// }



  },

  methods: {
    closeSideBar(){
      this.asideWidth = '0px'
      this.isCollapse = true
      // this.sidebarIsOpen = false
    },

     $_isMobile() {
       const { body } = document
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < this.bodyWiDthLevel
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        this.device = isMobile ? 'mobile' : 'desktop'

        if (isMobile) {
          this.closeSideBar()
          // this.sidebarIsOpen = false

        } else {
          this.drawer = false
          this.asideWidth = '130px'
          this.isCollapse = false
          // this.sidebarIsOpen = true
        }
      }
    },

    toggleCollapse () {

       if (this.device === 'mobile') {
         // console.log('mobile closesidebar')
         this.closeSideBar()
         this.drawer = true
       } else {
         this.isCollapse = !this.isCollapse
         this.asideWidth = this.asideWidth === '45px' ? '130px': '45px'
       }

    },
    modifyPassord() {
      this.$router.push('/modifypassword');
    },

    logout() {
      // localStorage.setItem('redirect', '/login');
      // 清除本地的 publicKey 和 jwtToken
      localStorage.removeItem('userName')
      localStorage.removeItem('publicKey');
      localStorage.removeItem('jwtToken');
      // 跳转到登录页面
      this.$router.push('/login');
    },
  }
};
</script>

<style>

.toggle-button {
    /*background-color: #4a5064;*/
    /*font-size: 10px;*/
    /*line-height: 24px;*/
  height: 20px;
    color: black;
    /*text-align: center;*/
    /*letter-spacing: 0.2em;*/
    cursor: pointer;
}
.outside-container {
  height: 100vh;
  display: flex;
}


 .aside-grid {
  display: flex;
    /*justify-content: space-between;*/
}

/*.headele {*/
/*  display: flex;*/
/*  justify-content: space-around;*/
/*}*/

.el-header, .el-footer {
  text-align: right;
  /*height: 1%;*/
  height: 60px
}

.el-aside {
  text-align: left;
  background-color: #faf9f5;
  /*line-height: 500px;*/
}


.el-main {
  /*background-color: #E9EEF3;*/
  color: #333;
  text-align: center;
  align-items: stretch;
}

.routerlink {
  color: black;
  text-decoration: none; /* 取消下划线 */
}

.logoJump {
  display: flex;
  justify-content: space-between;
}

.uname {
  display: flex;
  justify-content: right;
  align-items: center;
}

.login-container {
  /*background-image: url('header.png');*/
  background-size: cover;
}

.avatarimg {
  width: 30px; /* 调整头像大小 */
  height: 30px;
  border-radius: 50%; /* 将头像设置为圆形 */
  margin-right: 10px; /* 调整头像和文字之间的间距 */
}




</style>
