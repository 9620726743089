<template>
<el-menu>
        <router-link to="/home" class="routerlink">
              <img src='../../public/logo.png' class="avatarimg"/>
            </router-link>
        <router-link to="/home" class="routerlink">
          <el-menu-item index="1">
            <i class="el-icon-location"></i>
            <span>主页</span>
          </el-menu-item>
        </router-link>
        <router-link to="/sitepasswd" class="routerlink">
          <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <span>密码列表</span>
          </el-menu-item>
        </router-link>
        <router-link to="/task" class="routerlink">
          <el-menu-item index="3">
            <i class="el-icon-document"></i>
            <span>任务列表</span>
          </el-menu-item>
        </router-link>
        <router-link to="/cloudecslist" class="routerlink">
            <el-menu-item index="7">
              <i class="el-icon-document"></i>
              <span>服务器列表</span>
            </el-menu-item>
          </router-link>
        <router-link to="/chat" class="routerlink">
          <el-menu-item index="4">
            <i class="el-icon-document"></i>
            <span>ChatGPT</span>
          </el-menu-item>
        </router-link>

<!--<el-submenu index="8">-->
<!--        <template #title>-->
<!--            <i class="el-icon-timer"></i>-->
<!--            <span>定时任务</span>-->
<!--        </template>-->
<!--        <router-link to="/scheduledtask/scheduledtasklist" class="routerlink">-->
<!--            <el-menu-item index="8-1">-->
<!--                <span>任务列表</span>-->
<!--            </el-menu-item>-->
<!--        </router-link>-->
<!--        <router-link to="/scheduledtask/metadataMaintenance" class="routerlink">-->
<!--            <el-menu-item index="8-2">-->
<!--                <span>元数据维护</span>-->
<!--            </el-menu-item>-->
<!--        </router-link>-->
<!--    </el-submenu>-->
<!--        <router-link to="/memoryday" class="routerlink">-->
<!--            <el-menu-item index="6">-->
<!--              <i class="el-icon-document"></i>-->
<!--              <span>纪念日倒计时</span>-->
<!--            </el-menu-item>-->
<!--          </router-link>-->
      </el-menu>
</template>

<script>
export default {
  name: "sideBar",
  // data() {
  //   return {
  //     isOpen: false,  // 控制子菜单展开状态
  //   };
  // },
  // methods: {
  //   toggleMenu() {
  //     this.isOpen = !this.isOpen;  // 切换展开状态
  //   }
  // }
}
</script>

<style scoped>
/*.rotate-icon {*/
/*  transform: rotate(180deg); !* 旋转180度 *!*/
/*  transition: transform 0.3s; !* 平滑过渡 *!*/
/*}*/
.routerlink {
  color: black;
  text-decoration: none; /* 取消下划线 */

}
.el-menu {
  background-color: #faf9f5;
}
</style>